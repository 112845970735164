import Collapsible from "../Collapsible/Collapsible";
import { $titleStyle } from "./style";

function timestampToDateTime(timestamp) {
    if(!timestamp) return '--';
    const date = new Date(timestamp);
    return date.toLocaleString();
  }

function Deploy({ deploy, open }) {

    const statusColors = {
        Complete: 'green',
        Failed: 'red',
        Running: 'orange',
        New: 'blue',
        Pending: 'blue',
        Error: 'red',
        Cancelled: 'red'
      }
console.log('$titleStyle',$titleStyle(statusColors[deploy.status.phase] || 'gray'))
  return (
    <Collapsible title={deploy.metadata.name} open={open} style={$titleStyle(statusColors[deploy.status.phase] || 'gray')}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
        <p style={
            {color: statusColors[deploy.status.phase] || 'gray'}
        }
        >{deploy.status.phase}</p>
        <p>Inicio: {timestampToDateTime(deploy.metadata.creationTimestamp)} Fim: {timestampToDateTime(deploy.status.completionTimestamp)}</p>
        <p>Duração: {deploy.status.duration/1000000000} segundos</p>
        {deploy.status.reason && <p>Motivo: {deploy.status.reason}</p>}
        {deploy.status.message && <p>Mensagem: {deploy.status.message}</p>}
        {deploy.status.logSnippet && 
            <section style={{ border: "1px solid black", padding: "10px", width: "80%"}}>
                <h3 style={{margin: '0 0 0.5rem'}}>Log</h3>
                <text style={{ whiteSpace: "pre-line" }}>{deploy.status.logSnippet}</text>
            </section>
        }
        </div>
    </Collapsible>
  );
}

export default Deploy;