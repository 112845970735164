import Deploy from '../Deploy/Deploy';

function ListaDeploys({ deploys }) {
  return (
    <>
      {deploys?.sort((a, b) => new Date(b.metadata.creationTimestamp) - new Date(a.metadata.creationTimestamp))
        .slice(0, 10)
        .map((deploy,index) => (
        <div key={deploy.metadata.name}>
          <Deploy deploy={deploy} open={index===0} />
        </div>
      ))}
    </>
  );
}

export default ListaDeploys;