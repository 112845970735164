import React, { useState } from 'react';

function Collapsible({ children, open, title, style }) {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <div>
        <button onClick={() => setIsOpen(!isOpen)} 
        className={style}>
            {title||'Expandir'}
        </button>
      {isOpen && children}
    </div>
  );
}

export default Collapsible;