import { css } from '@emotion/css';

export const $titleStyle = (color) => css`
    color: ${color};
    display: inline-block;
    width: 90%;
    font-size: 1.5rem;
    font-weight: 600;
    border: 1px solid #000;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 0.5rem;
    background-color: #fff;
`;