import './App.css';
import axios from 'axios';
import { useEffect, useState } from 'react';

import ListaDeploys from './components/ListaDeploys/ListaDeploys';


function App() {

  const [deployments, setDeployments] = useState([])
  
  //get Deploys from OpenShift 
  // API https://webhook-openshift.mti.mt.gov.br/apis/build.openshift.io/v1/builds
  function getData(){
    let token= `eyJhbGciOiJSUzI1NiIsImtpZCI6InVMeGVSSkdrRFFpb3hQeGJ2N01QNmU1MDlYYVdRbGg3R1J2SGd3UlBLZlUifQ.eyJpc3MiOiJrdWJlcm5ldGVzL3NlcnZpY2VhY2NvdW50Iiwia3ViZXJuZXRlcy5pby9zZXJ2aWNlYWNjb3VudC9uYW1lc3BhY2UiOiJkZWZhdWx0Iiwia3ViZXJuZXRlcy5pby9zZXJ2aWNlYWNjb3VudC9zZWNyZXQubmFtZSI6ImFwaS1zZXJ2aWNlLWFjY291bnQtdG9rZW4tOGRmaGoiLCJrdWJlcm5ldGVzLmlvL3NlcnZpY2VhY2NvdW50L3NlcnZpY2UtYWNjb3VudC5uYW1lIjoiYXBpLXNlcnZpY2UtYWNjb3VudCIsImt1YmVybmV0ZXMuaW8vc2VydmljZWFjY291bnQvc2VydmljZS1hY2NvdW50LnVpZCI6IjU3NzcwODc3LTNlODItNDgxOS05YmY0LWMxZmY4Zjc1MGExZiIsInN1YiI6InN5c3RlbTpzZXJ2aWNlYWNjb3VudDpkZWZhdWx0OmFwaS1zZXJ2aWNlLWFjY291bnQifQ.ClaLDJOkGHhyn-I8mdj82FECqaDQa986aer65tlEbI1Elwic0JoQLzB5LmFHAOLqV0cKs05Q1RI2LCQI7rMwET1Ew84GernhDtYl_kqBgvHVyTsJM0pQLZDVSYx_qLBavLgV5oktsDJ-OZ4SWxDb3WH8BM7e29U0Vm1qejeAMXhy4SWsR7zHxKpb2RUU4LF_do4MCGCqyZGAASnpEPmi7P4Xl1-0Vwi4mjYYFeKgMwyrx_W99Wy00fwnJ6nfbzofxoyzKxAOQNiSmAhgYcPOHF5-oaYOKRYINMYStcDBpDMvLwxU44xy_yXTmJpSu_OsCwRkyMQ8f7ajijK5Nda3WxPCqCiUoFDSUocmxKsJXTQPRCM9esKqVA07S8Tig_20WO64Ew1UAJY4w242oW-tASPRRenblTGHL9OxcH3ewFmTHjd0RUGifJoIuNvPBodknB9kuVpA-6s1PqlXtF3rgLjPq9dp_gLO3VjhmqryvE3fxoNuR3dh6pu4VM8OQHAnO7rDWD4JIeeKlfmFXDsVEvllPo0isOrzRWCZ9BeJtqfKVDn0Ppbaz3l_aczOXhGkc_eyhySm3Q5gjc19geAZaXoqz1VAwjEjPSeDrkOey8qBj0JQJYkEahkq8lvscZ-V2y3pxsOk6dwh7HiRkS6TWndH3L2r33r0n_MKg3TeJvo`
    axios.get(`https://webhook-openshift.mti.mt.gov.br/apis/build.openshift.io/v1/builds`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      setDeployments(res.data.items)
    })
  }

  useEffect(() => {
    getData()
    // update every 10 seconds
    const interval = setInterval(() => {
      getData()
    }, 10000);
    return () => clearInterval(interval);
  }, [])

  const pathname = window.location.pathname.replace('/', '');

 
  return (
    <div className="App">
      {!pathname? <h1>Informe o projeto no pathname</h1> :
      <>
        <h1>Deploys</h1>
        <ListaDeploys deploys={deployments.filter(deploy => deploy.metadata.name.includes(pathname))} />
      </>
      }
    </div>
  );
}

export default App;
